import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description, image, keywords }) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)
  
    const {
        defaultTitle,
        defaultDescription,
        siteUrl,
        defaultImage,
        defaultKeywords
    } = site.siteMetadata

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image:`${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
        keywords: keywords || defaultKeywords
    }

    return (
        <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />)}
            {seo.image && <meta property="og:image" content={seo.image} />}
        </Helmet>
    )
  }
  
  export default Seo

  Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    keywords: PropTypes.bool,
  }
  Seo.defaultProps = {
    title: null,
    description: null,
    image: null,
    keywords: false
  }

  const query = graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl: siteUrl
          defaultImage: image
          defaultKeywords: keywords
        }
      }
    }
  `